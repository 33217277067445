import { useSession, useSupabaseClient } from "@supabase/auth-helpers-react";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { useRouter } from "next/router";
import { ComponentPropsWithoutRef, FC } from "react";
import { DynamicHead } from "../components/DynamicHead";
import Header from "../components/Header";
import Typography from "../components/Typography";
import toast from "react-hot-toast";

type LoginProps = {} & ComponentPropsWithoutRef<"div">;

function removeCookie(
  name: string,
  path: string = "/",
  domain?: string,
  secure: boolean = false,
  sameSite: "strict" | "lax" | "none" = "lax"
): void {
  let cookieString = `${encodeURIComponent(
    name
  )}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=${path};`;

  if (domain) {
    cookieString += ` domain=${domain};`;
  }

  if (secure) {
    cookieString += ` secure;`;
  }

  cookieString += ` SameSite=${sameSite};`;

  console.log("Deleting", cookieString);

  document.cookie = cookieString;
}

const Login: FC<LoginProps> = ({ ...rest }) => {
  const supabaseClient = useSupabaseClient();
  const session = useSession();
  const router = useRouter();

  if (session) router.push((router.query.redirect as string) ?? "/dashboard");
  return (
    <>
      <DynamicHead
        title="Klap | Login"
        description="Register or login to your Klap account."
      />
      <div {...rest} className={`${rest.className}`}>
        <Header />
        <main className="max-w-xl mx-auto mt-32 px-3">
          <Typography variant="h4" component="h1" className="text-center mb-8">
            Log in
          </Typography>

          <Auth
            view="sign_in"
            redirectTo={
              (process.env.NEXT_PUBLIC_WEB_URL as string) +
                router.query.redirect ?? "/dashboard"
            }
            magicLink
            providers={["google"]}
            supabaseClient={supabaseClient}
            appearance={{
              theme: ThemeSupa,
              extend: true,
              className: {
                button: "!bg-black !rounded-xl !border-none !text-white",
                input: "!rounded-xl",
                message: "bg-gray-100 !p-2 !rounded-xl text-black",
              },
            }}
          />
          <Typography
            onClick={() => {
              // Remove all cookies
              for (const domain of [
                undefined,
                "localhost",
                "klap.app",
                ".klap.app",
                "new.klap.app",
              ]) {
                for (const samesite of ["strict", "lax", "none"]) {
                  removeCookie(
                    "supabase-auth-token",
                    "/",
                    domain,
                    true,
                    samesite as "strict" | "lax" | "none"
                  );
                }
              }
              // Remove all local storage
              toast.success("Cleared site data. Please try again.");
            }}
            variant="body2"
            className="text-center mt-4 text-gray-500 cursor-pointer"
          >
            Having trouble logging in? Click Here
          </Typography>
        </main>
      </div>
    </>
  );
};

export default Login;
